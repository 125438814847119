.chate {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
}

.chat__body {
  width: 100%;
  background-color: #fffbf5;
  padding: 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1efef;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(223, 223, 223);
    border-radius: 6px;
  }
}

.chat__reciver {
  position: relative;
  font-size: 13px;
  padding: 10px;
  width: fit-content;
  background-color: #dddddd;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 260px;
  margin-top: 10px;
}
.time__stamb {
  margin-left: 10px;
  font-size: xx-small;
}
.chat__name {
  position: absolute;
  left: 0;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}
.chat__message {
  margin-left: auto;
  background-color:  rgb(20,60,96) !important;
  color: #fdfdfd;
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  padding: 10px;
  max-width: 260px;
}

.chat__footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
}
.chat__footer > form {
  margin-top: 30px;
  flex: 1;
  display: flex;
  padding: 6px 10px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}

.chat__footer > form > input {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
}

.chat__footer > form > button {
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s ease-in;
  background-color:  rgb(20,60,96) !important;
  color: #fcfcfc;
  padding: 0 25px;
}
.chat__footer > form > button:hover {
    background-color:  rgb(8, 36, 61) !important;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
}


@media (max-width: 600px) {
  .chate {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
  }

  .chat__body {
    width: 100%;
    background-color: #fffbf5;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1efef;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(223, 223, 223);
      border-radius: 6px;
    }
  }

  .chat__reciver {
    position: relative;
    font-size: 11px;
    padding: 10px;
    width: fit-content;
  background-color: #dddddd;

    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 230px;
  }
  .time__stamb {
    margin-left: 10px;
    font-size: xx-small;
  }
  .chat__name {
    position: absolute;
    left: 0;
    top: -15px;
    font-weight: 800;
    font-size: xx-small;
  }
  .chat__message {
    margin-left: auto;
    background-color:  rgb(20,60,96) !important;
    color: #fdfdfd;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 11px;
    padding: 10px;
    max-width: 230px;
  }

  .chat__footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
    height: 62px;
    border-top: 1px solid lightgray;
  }
  .chat__footer > form {
    margin-top: 30px;
    flex: 1;
    display: flex;
    padding: 6px 7px;
    border: 1px solid #c0c0c0;
    border-radius: 8px;
  }

  .chat__footer > form > input {
    flex: 1;
    border-radius: 30px;
    padding: 7 0px;
    border: none;
    outline: none;
    background: transparent;
  }

  .chat__footer > form > button {
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.4s ease-in;
    background-color:  rgb(20,60,96) !important;
    color: #fcfcfc;
    padding: 0 20px;
  }
  .chat__footer > form > button:hover {
    background-color:  rgb(8, 36, 61) !important;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
  }
}
