.headerIcon {
  position: relative;
  .ant-avatar {
    float: right;
    margin-left: 10px;
    margin-top: 15px;
    color: #4f5d75;
    background: transparent;
    &:hover {
      background: #fff;
      box-shadow: 0px 0px 10px 4px rgba(150, 190, 238, 0.3);
    }
  }
  .last {
    margin-right: 30px;
    background-color: #f7f7f7;
  }
 
}


.profileDropdown {
  .pad15 {
    display: flex;
  }
  .info {
    float: left;
    display: inline;
    padding-left: 15px;
    p {
      margin-bottom: 0;
    }
  }
}
