.mothlyTargetBox{
    &::-webkit-scrollbar{
        width: 3px;
      }
      &::-webkit-scrollbar-track{
        background-color: #F1EFEF;
      }
      &::-webkit-scrollbar-thumb{
        background-color: rgb(223, 223, 223);
        border-radius: 6px;
      }
}